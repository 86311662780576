import styled from '@emotion/styled';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import { OptimizedImage } from '@web/common/components/optimized-image';
import NextLink from 'next/link';
import { FC } from 'react';

const Logo = styled.div`
  width: 153.12px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.theme.spacing(1, 0)};
  a {
    display: inline-flex;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    margin: ${props => props.theme.spacing(0)};
  }
`;

const Page = styled.div`
  max-width: 100%;
  width: 100vw;
  min-height: 100vh;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: ${props => props.theme.spacing(8)};
  margin-bottom: ${props => props.theme.spacing(2)};
  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: ${props => props.theme.spacing(2)};
  }
`;

const CTAButton = styled(Button)`
  min-width: 165px;
`;

const ErrorPage: FC = () => (
  <Page>
    <Container fixed>
      <Header>
        <Logo>
          <NextLink href="/" passHref>
            <Link title="Veroo" underline="none">
              <OptimizedImage src="/images/logo-veroo.svg" ext="svg" height="60" alt="Veroo" />
            </Link>
          </NextLink>
        </Logo>
      </Header>
      <Content>
        <OptimizedImage src="/icons/error.svg" width="224" height="221" />
        <Box my={2}>
          <Typography variant="h3">Ops! Aqui não tem café (404)</Typography>
        </Box>
        <Typography variant="body1">
          Infelizmente a página que está tentando acessar não existe.
        </Typography>
        <Typography variant="body1">
          Contudo você pode ver os melhores cafés no botão abaixo.
        </Typography>
        <Box mt={3}>
          <NextLink href="/" passHref>
            <Link underline="none">
              <CTAButton variant="contained" color="primary" size="large">
                Voltar para o site
              </CTAButton>
            </Link>
          </NextLink>
        </Box>
      </Content>
    </Container>
  </Page>
);

// export default AboutUs;
export default ErrorPage;
